<script setup>
// eslint-disable-next-line no-undef
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

// eslint-disable-next-line no-undef
const props = defineProps({
  option: {
    required: true,
    type: Object
  },
  type: {
    default: null
  }
})

// use
const store = useStore()

// methods
const step = computed(() => {
  return store.getters['configurator/currentStep']
})
const priceAll = computed(() => {
  const currentStep = store.getters['configurator/currentStep']
  return currentStep.price_all || false
})
const loading = computed(() => {
  return priceAll.value && totalPrice.value.loading
})
const totalPrice = computed(() => {
  // const totalPrice = store.getters['configurator/totalPrice']
  return getTotalPrice(props.option.id)
  // return totalPrice(currentOption.value)
})
const price = computed(() => {
  const option = props.option
  if (!option) {
    return null
  }
  if (priceAll.value) {
    return totalPrice.value.discounted || totalPrice.value.total
  }
  if (option.price) {
    if (!isNaN) {
      return option.price.discounted
    } else {
      if (option.discount > 0) {
        const discount = (Number.parseFloat(option.discount) / 100) * option.price.self
        let totalDiscount = 0
        totalDiscount = option.price.self - discount
        return totalDiscount
      } else {
        return option.price.self
      }
    }
  }
  return 0
})
const oldPrice = computed(() => {
  const option = props.option
  if (!option) {
    return null
  }
  if (priceAll.value) {
    return totalPrice.value.old_price
  }
  if (option.price) {
    return option.price.self
  }
  return 0
})
// methods
function onClickOption () {
  if (props.option.disabled) {
    return
  }
  if (props.option.type === 'check') {
    store.dispatch('system/toggleOption', props.option)
  } else {
    store.dispatch('system/selectMainOption', props.option)
  }
}
// function selectSubOption ({ optionId, subOptionId, e }) {
//   store.dispatch('configurator/selectSubOption', { optionId: optionId, subOptionId: subOptionId, e: e })
// }
function getTotalPrice (id) {
  const prices = store.state.configurator.totalPrices
  return prices.find(o => o.id === id)
}

</script>
<template>
  <div class="card pointer" :class="{ 'not-selected': option.disabled }">
    <Tooltip :tooltip="option.tooltip" v-if="type === 'two-column'">
      <button class="btn-reset btn-tooltip">
        <i class="fas fa-info-circle"></i>
      </button>
    </Tooltip>
    <Tooltip :tooltip="option.tooltip" v-else-if="step.name !== 'Muster'">
      <button class="btn-reset btn-tooltip">
        <i class="fas fa-info-circle"></i>
      </button>
    </Tooltip>
    <!-- image -->
    <div v-if="type === 'two-column'" @click="onClickOption" class="text-center pt-3" :class="type">
      <img class="card-img-top" :src="$filters.image(option.image, '190x')">
    </div>
    <div v-else-if="step.name === 'Muster'" @click="onClickOption" class="text-center pt-3" :class="type">
      <Tooltip :tooltip="option.tooltip">
        <img class="card-img-top" :src="$filters.image(option.image, '350x')" v-if="type === 'option-2'">
        <img class="card-img-top" :src="$filters.image(option.image, '190x')" v-else>
      </Tooltip>
    </div>
    <div v-else @click="onClickOption" class="text-center pt-3" :class="type">
      <img class="card-img-top" :src="$filters.image(option.image, '350x')" v-if="type === 'option-2'">
      <img class="card-img-top" :src="$filters.image(option.image, '190x')" v-else>
    </div>
    <div @click="onClickOption" class="card-body text-center py-1 px-0">
      <p class="card-title"><span v-if="option.quantity">{{ option.quantity }}x</span>{{ option.name }}</p>
      <div class="text-center description" v-html="option.description"></div>
    </div>
<!--    <spiner v-if="loading" size="2" color="text-primary"/>-->
<!--    <template v-else>-->
<!--      <p class="text-center text-danger price-size">-->
<!--        <span>-->
<!--          <span class="badge bg-danger" v-if="option.discount > 0">-{{ option.discount }}%</span>-->
<!--          <span class="option__price option__price&#45;&#45;discount" v-if="option.show_zero_price">{{ $filters.currency(price) }}</span>-->
<!--          <span class="option__price option__price&#45;&#45;old" v-if="option.discount > 0">{{ $filters.currency(oldPrice) }}</span>-->
<!--        </span>-->
<!--      </p>-->
<!--    </template>-->
    <div class="card-footer bg-primary text-white text-center" :class="{'not-selected': !option.selected}" @click="onClickOption">
      <span v-if="option.selected">Ausgewählt</span>
      <span v-else>Auswählen</span>
    </div>

  </div>
</template>
<style scoped>
.not-selected {
  opacity: .5;
}
</style>
