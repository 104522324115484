<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import Slider from '~/pages/configurator/common/slider'
import SelectOption from './option/selectOption'
import CountOption from './option/countOption'
import { nextTick } from '@vue/runtime-core'

export default {
  name: 'Simpleselect',
  components: {
    Slider,
    selectOption: SelectOption,
    countOption: CountOption,
    checkOption: SelectOption
  },
  setup () {
    const store = useStore()
    const category = ref(null)
    const showAll = ref(false)
    const componentKey = ref(0)
    const page = ref(1)
    const perPage = ref(6)

    const step = computed(() => {
      return store.getters['system/currentStep']
    })
    const currentOptions = computed(() => {
      return store.getters['system/currentOptions']
    })
    const categories = computed(() => {
      if (step.value.categories) {
        return step.value.categories
      }
    })
    const startPage = computed(() => {
      const index = currentOptions.value.findIndex(o => o.selected)
      const page = Math.ceil((index + 1) / perPage.value)
      if (page === 0) {
        return 1
      } else {
        return page
      }
    })
    const options = computed(() => {
      if (category.value !== null) {
        return currentOptions.value.filter(o => o.category && o.category.indexOf(category.value) > -1)
      }
      nextTick(() => {
        forceRerender()
      })
      return currentOptions.value
    })
    // methods
    function setCategory (catId) {
      category.value = catId
      const selected = categories.value.find(c => c.selected)
      const cat = categories.value.find(c => c.id === catId)
      cat.selected = !cat.selected
      if (!cat.selected) {
        category.value = null
      }
      if (typeof selected !== 'undefined') {
        selected.selected = false
      }
      nextTick(() => {
        forceRerender()
      })
    }
    function showAllModel () {
      showAll.value = !showAll.value
    }
    function forceRerender () {
      componentKey.value += 1
    }
    return {
      category,
      showAll,
      componentKey,
      page,
      perPage,
      step,
      categories,
      startPage,
      options,
      setCategory,
      showAllModel
    }
  }
}
</script>
<template>
    <h3 class="heading-title" v-if="step.name === 'Muster'">Wählen Sie jetzt das Muster für Ihre Zaunanlage.</h3>
    <h3 class="heading-title" v-if="step.name === 'Form'">Wählen Sie jetzt die Verlaufsform aus.</h3>
    <h3 v-if="step.description" class="step-description">{{ step.description }}</h3>
<!--    <div class="mb-4" v-if="step.name == 'Muster'">-->
<!--      <button class="btn" :class="{ 'btn-warning': showAll, 'btn-outline-warning': !showAll }" @click="showAllModel">-->
<!--        <span v-if="!showAll">Alle Muster anzeigen</span>-->
<!--        <span v-else-if="showAll">Alle Muster verbergen</span>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div v-if="showAll" class="row">-->
<!--      <div v-for="option in options" :key="option.id" class="col-2 mb-4">-->
<!--        <component :is="(option.type || 'select') + 'Option'" :key="option.id" :option="option" />-->
<!--      </div>-->
<!--    </div>-->
  <Slider v-if="!showAll"
          ref="slider"
          :itemClass="step.css"
          :init-page="startPage"
          chevron="true"
          :key="componentKey">

    <component v-for="option in options"
               :is="(option.type || 'select') + 'Option'"
               :key="option.id"
               :option="option"
               :type="step.css" />
  </Slider>

</template>
